<template>
  <div class="gy_autograph">
    <div v-if="showGraph">
      <van-nav-bar
        title="盘点签字"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        fixed
      >
      </van-nav-bar>
      <div class="content">
        <div class="block1">
          <div class="title">
            <span style="font-weight: 500">主盘部门</span>
            <span>{{master_dep}}</span>
          </div>
          <div class="graph_img" v-if="isGraph1">
            <img class="" :src="imgBase1" alt="" />
          </div>
          <div v-else class="not_graph">
            <div @click="showGraph = false, touchArea = 1">
              <img class="add_img" src="../../assets/add.png" alt="" />
              <div class="text">添加签名</div>
            </div>
          </div>
          <div class="clearGraph" @click="isGraph1 = false; imgBase1 = ''">清除</div>
        </div>
        <div class="block1">
          <div class="title">
            <span style="font-weight: 500">随盘部门</span>
            <span>{{slave_dep}}</span>
          </div>
          <div class="graph_img" v-if="isGraph2">
            <img class="" :src="imgBase2" alt="" />
          </div>
          <div v-else class="not_graph">
            <div @click="showGraph = false, touchArea = 2">
              <img class="add_img" src="../../assets/add.png" alt="" />
              <div class="text">添加签名~</div>
            </div>
          </div>
          <div class="clearGraph" @click="isGraph2 = false; imgBase2 = ''">清除</div>
        </div>
      </div>
      <div class="btn_block">
        <van-button round block type="info" @click="submit" :class="{'btn_true': isTrue}">确定</van-button>
      </div>
    </div>
    <div v-else>
      <Graph @setImgBase="setImgBase" @goBack="goBack" />
    </div>
  </div>
</template>
<script>
import Graph from "../../components/graph.vue";
export default {
  data() {
    return {
      departmentId: '',
      showGraph: true,
      imgBase1: "",
      imgBase2: "",
      isGraph1: false,
      isGraph2: false,
      touchArea: 1,
      master_dep: '',
      slave_dep: ''
    };
  },
  computed: {
    isTrue() {
      return (this.imgBase1 && this.imgBase2)
    }
  },
  components: {
    Graph,
  },
  mounted() {
    this.departmentId = this.$route.query.department
    this.getDep()
  },
  methods: {
    getDep() {
      this.$http({
        url: "/project/ms/dep",
        method: "get",
        params: {dep: this.departmentId}
      }).then(res => {
        if (res.code === 1) {
          console.log('res', res)
          this.master_dep = res.data.master_dep
          this.slave_dep = res.data.slave_dep
        } else {
          this.$toast.fail(res.msg);
        }
      })
    },

    onClickLeft() {
      this.$router.push({name: 'checkProject'})
    },

    setImgBase(data) {
      this.showGraph = true;
      if (this.touchArea === 1) {
        this.isGraph1 = true;
        this.imgBase1 = data;
      } else if(this.touchArea === 2) {
        this.isGraph2 = true;
        this.imgBase2 = data;
      }
    },

    dataURLtoFile(dataurl,filename = '') {
      var arr = dataurl.split(','),
        // mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      // if (mime) {
      //   var mime_arr = mime.split('/')
      //   if (filename == '') {
      //     filename = randomStr(6) + '.' + mime_arr[1]
      //   }
      // }
      return new File([u8arr], filename)
    },

    goBack() {
      this.showGraph = true;
    },

    submit() {
      if (!(this.imgBase1 && this.imgBase2)) {
        this.$toast.fail('请添加签名！')
        return
      }
      let formData1 = new FormData()
      formData1.append("file", this.dataURLtoFile(this.imgBase1, 'a.png'))
      formData1.append("category", 'ms')
      let formData2 = new FormData()
      formData2.append("file", this.dataURLtoFile(this.imgBase2, 'b.png'))
      formData2.append("category", 'ms')

      let p1 = this.$http({
        url: "/common/upload",
        method: "post",
        data: formData1
      })
      let p2 = this.$http({
        url: "/common/upload",
        method: "post",
        data: formData2
      })

      Promise.all([p1, p2]).then(res => {
        // console.log('66', res)
        if (res[0].code === 1 && res[1].code === 1) {
          let params = {
            department: this.departmentId,
            master_img: res[0].data.url,
            slave_img: res[1].data.url
          }
          this.$http({
            url: "/project/sign",
            method: "post",
            data: params
          }).then(res => {
            if (res.code === 1) {
              console.log('res', res)
              this.$router.push({name: 'departDetail', query: {department: this.departmentId, status: this.$route.query.status, name: this.$route.query.name}})
            } else {
              this.$toast.fail(res.msg);
            }
          })
        }
      })
    }
  },
};
</script>

<style scoped lang="scss">
.gy_autograph {
  padding-top: 56px;
  .content {
    padding: 0 10px 10px;
    padding-bottom: 70px;
    .block1 {
      height: 300px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgb(219, 219, 235);
      border-radius: 10px;
      padding: 15px;
      margin-top: 10px;
      .title {
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f5f6f7;
        padding-bottom: 20px;
      }
      .graph_img {
        height: 80%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .not_graph {
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        .add_img {
          width: 50px;
        }
        .text {
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #bcbcbc;
          margin-top: 10px;
          margin-left: -5px;
        }
      }
      .clearGraph {
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0085f5;
        float: right;
      }
    }
  }
  .btn_block {
    height: 68px;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    width: 100%;
    .van-button {
      width: 80%;
      margin: 12px auto 0;
      background: #E2EBF2;
      border-color: #E2EBF2;
      color: #BDC8D1;
    }
    .btn_true {
      color: #fff;
      background-color: #1989fa;
      border: 1px solid #1989fa;
    }
  }
}
</style>