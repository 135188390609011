<template>
  <div>
    <!-- <button @click="startScan($event)">扫一扫</button> -->
    <div class="container">
      <div id="interactive" class="viewport"></div>
    </div>
    <van-button block type="info" @click="stopScan()" style="width: 120px;border-radius: 10px;margin: auto;margin-top: 50px;">停止扫描</van-button>
  </div>
</template>
<script>
import Quagga from "quagga";

export default {
  data() {
    return {
      // code: "",
      config: {
        frequency: 5,
        numOfWorkers: 2,
        locate: true,
        inputStream: {
          name: "Live",
          type: "LiveStream",
          constraints: {
            width: 800,
            height: 600,
            deviceId: 0,
            facingMode: "environment",
          },
          area: {
            top: "0%",
            right: "0%",
            left: "0%",
            bottom: "0%",
          },
        },
        decoder: {
          readers: ["code_128_reader", "code_39_reader"],
        },
        locator: {
          halfSample: true,
          patchSize: "medium",
        },
      },
      capacity: 5,
      resultCollector: null,
    };
  },

  mounted() {
    const _this = this;
    Quagga.onDetected(function (data) {
      console.log("条形码：", data.codeResult.code);
      _this.$emit("onSuccess", data.codeResult.code, data.codeResult);
      Quagga.stop();
    });
    this.startScan()
  },
  methods: {
    startScan() {
      const _this = this;

      Quagga.init(
        _this.config,
        (err) => {
          if (err) {
            console.dir(err);
            alert("error of init camera");
            return;
          }
          _this.resultCollector = Quagga.ResultCollector.create({
            capture: true, // keep track of the image producing this result
            capacity: _this.capacity, // maximum number of results to store
            blacklist: [
            ],
            filter: function (_codeResult) {
              console.log('_codeResult', _codeResult)
              return true;
            },
          });
          Quagga.registerResultCollector(_this.resultCollector);
          Quagga.start();
        }
      );
    },
    stopScan() {
      Quagga.stop();
      this.$router.push({name: 'checkProject'})
    },
  },
  // destroyed() {
  //   this.stopScan();
  // },
};
</script>
<style>
#interactive.viewport {
  position: relative;
  display: inline-block;
}

#interactive.viewport > canvas,
#interactive.viewport > video {
  width: 100%;
  max-width: 100%;
  height: 100%;
}
#interactive.viewport > video {
  z-index: 50;
}
canvas.drawing,
canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
}
</style>
<style scoped>
.container {
  overflow: hidden;
  max-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
