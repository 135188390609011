<template>
  <div v-if="showName" class="systemName">
    <van-popup v-model="showName" round position="bottom" class="TH_class" :close-on-click-overlay="false">
      <van-nav-bar
        title="添加资产名称"
        left-arrow
        @click-left="name_onClickLeft"
        @click-right="name_onClickRight"
        fixed
      >
        <template #left> 取消 </template>
        <template #right> 确定 </template>
      </van-nav-bar>
      <div class="name_content">
        <van-field
          v-model="system_name"
          placeholder="请输入资产编号、名称、品牌、规格型号"
        />
        <div class="title">
          <span class="title_text">{{department_name}}</span>
          相关名称推荐
        </div>
        <div class="items">
          <span v-for="(item, index) in datalist" :key="index" :class="[{'active': active === index} , 'item']" @click="item_click(item, index)">{{item}}</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      system_name: '',
      datalist: [],
      active: ''
    }
  },
  props: [
    'showName',
    'department_name',
    'department'
  ],
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let params = {
        dep: this.department,
      };
      this.$http({
        url: "/inventory/itemsearch",
        method: "post",
        data: params,
      }).then((res) => {
        if (res.code === 1) {
          this.datalist = res.data;
        } else {
          this.$toast.fail(res.msg);
        }
      })
    },

    name_onClickLeft() {
      this.$emit('close_systemName')
    },
    name_onClickRight() {
      this.$emit('close_systemName', this.system_name)
    },

    item_click(item, index) {
      this.system_name = item
      this.active = index
    }
  }
}
</script>

<style scoped lang="scss">
  .systemName /deep/ {
    top: 50px;
    .van-nav-bar--fixed {
      position: unset;
    }
    .name_content {
      padding-bottom: 40px;
      .van-field__value {
        height: 40px;
        line-height: 40px;
        background: #F7F8F9;
        border-radius: 10px;
        padding-left: 15px;
      }
      .title {
        font-size: 12px;
        margin: 13px 16px;
        color: #8d8585;
        .title_text {
          color: #1989fa;
        }
      }
      .items {
        padding: 0 16px;
        display: flex;
        flex-wrap: wrap;
        max-height: 350px;
        overflow: auto;
        .item {
          font-size: 13px;
          background-color: #f7f8f9;
          padding: 10px;
          width: 25%;
          text-align: center;
          margin-right: 8px;
          border-radius: 6px;
          margin-bottom: 10px;
        }
        .active {
          background-color: #e9f4ff;
          color: #1989fa;
        }
      }
    }
  }
</style>