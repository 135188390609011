
import axios from 'axios'
import router from '../route/index.js';
import { Toast  } from 'vant';
 
const instance = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? "https://api.bjguyou.com/" : process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "client-type": 3,
  },
})
// VUE_APP_API_URL =  'http://bjguyou.bjxiaomai.cn/'

instance.interceptors.request.use(
  config => {
    // store.state.loading = true;
    localStorage.setItem('TIME', new Date().getTime())
    // alert(localStorage.getItem('TIME'),11)
    if (localStorage.getItem("XS_TOKEN")) {
      config.headers["token"] = localStorage.getItem("XS_TOKEN");
    }
    return config;
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use((response) => {
  // console.log('888', response)
  
  // if (response.data.code === 401) {
  //   localStorage.removeItem('TIME')
  //   Toast.fail('用户身份过期，请重新登录！')
  //   router.push({name: 'login'})
  // }
  if(response.status !== 200) {
    return Promise.reject(response.data)
  }
  return response.data
},err => {
  console.log('err', err.response.status)
  if (err.response.status === 401) {
    localStorage.removeItem('TIME')
    Toast.fail('用户身份过期，请重新登录！')
    router.push({name: 'login', params: {from: 'other'}})
  } else {
    Toast.fail('接口请求失败，请联系管理员！')
  }
  Promise.reject(err)
})

export default instance