import Vue from 'vue'
import App from './App.vue'
import http from './utils/http'
import router from './route'
import Vant, { Dialog, Toast  } from 'vant';
import 'vant/lib/index.css';
import './home.css';
import './component.scss';
import common from './utils/common.js';
import compressorImage from './utils/compressorImage.js';
import API from './api.js'
import SignCanvas from 'sign-canvas';


Vue.use(SignCanvas);
Vue.use(Vant);
Vue.use(Dialog);
Vue.use(Toast);

Vue.config.productionTip = false
Vue.prototype.$API = API
Vue.prototype.common = common;
Vue.prototype.compressorImage = compressorImage;

Vue.prototype.$http = http

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
