<template>
  <div class="gy_checkProject">
    <van-nav-bar title="盘点项目" @click-right="onClickRight" fixed style="position: relative;">
      <template #right>
        <van-icon name="search" />
      <van-button block type="info" @click="scanCode" style="position: absolute;right: 70px;width: 75px;height: 28px;">扫一扫</van-button>
      </template>
    </van-nav-bar>

    <div class="mask" v-if="showScreen" @touchmove.prevent>
      <van-nav-bar title="搜索盘点资产" left-arrow @click-left="screen_onClickLeft" @click-right="screen_onClickRight">
        <template #right>
          <span class="search">搜索</span>
        </template>
      </van-nav-bar>
      <van-field v-model="kw" placeholder="请输入资产编号、名称、品牌、规格型号、盘点编号" />
      <div style="height: 100%" @click="showScreen = false"></div>
    </div>
    <!-- <van-dropdown-menu>
      <van-dropdown-item v-model="value" :options="option" />
      <van-dropdown-item title="筛选" ref="item">
        <van-cell center title="包邮">
          <template #right-icon>
            <van-switch v-model="switch1" size="24" active-color="#ee0a24" />
          </template>
        </van-cell>
        <van-cell center title="团购">
          <template #right-icon>
            <van-switch v-model="switch2" size="24" active-color="#ee0a24" />
          </template>
        </van-cell>
        <div style="padding: 5px 16px;">
          <van-button type="danger" block round @click="onConfirm">
            确认
          </van-button>
        </div>
      </van-dropdown-item>
    </van-dropdown-menu> -->

    <div class="content" id="scrollDiv">
      <div class="info">
        <div
          class="tag"
          :style="{
            background: dataSource.project.status ? '#fff' : '#f59f03',
            color: dataSource.project.status ? '#0085f5' : '#fff',
          }"
        >
          {{ dataSource.project.status ? "已完成" : "盘点中" }}
        </div>
        <div class="row">
          名称
          <span class="text">{{ dataSource.project.name }}</span>
        </div>
        <div class="row">
          编号
          <span class="text">{{ dataSource.project.project_no }}</span>
        </div>
        <div class="row" style="margin-top: '30px">
          盘点人
          <span class="text" style="margin-left: 1.6rem;">{{ dataSource.project.operater.join(',') }}</span>
        </div>
      </div>
      <div class="table" ref="www">
        <div class="header">
          <span class="header_left">部门列表</span>
          <span class="header_right">{{ dataSource.department.length }}部门</span>
        </div>
        <div v-for="(item, index) in dataSource.department" :key="index" class="item" @click="showModal(item)">
          <div class="title">
            <div class="name">{{ item.name }}</div>
            <div>
              <span
                class="circle"
                :style="{ background: item.status ? '#27E253' : '#F59F03' }"
              ></span>
              <van-icon name="arrow" class="icon" />
            </div>
          </div>
          <div class="text">{{ item.location }}</div>
          <div class="text">
            <span
              @click.stop="stat_assets(item.name, item.id)"
              >待盘点:<span style="color: #f59f03">{{ item.to_counted }}</span></span
            >
            <span style="margin-left: 50px">已盘点:{{ item.counted }}</span>
          </div>
        </div>
      </div>
      <!-- <div>

            <van-button round block type="info" @click="aa">跳转</van-button>
          </div> -->
    </div>
  </div>
  <!-- <StayAssets v-if="!showHome" :stayAssets_name="stayAssets_name" :stayAssets_id="stayAssets_id" @setShowHome="showHome = true"></StayAssets> -->
</template>
<script>
// import StayAssets from '../stayAssets/index.vue'
export default {
  layout: "login",
  data() {
    return {
      showScreen: false,
      dataSource: {},
      kw: '',
      showDaiAssets: false,
      showHome: true,
      stayAssets_name: '',
      stayAssets_id: ''
    };
  },
  mounted() {
    this.getData()
  },
  // components: {
  //   StayAssets
  // },
  methods: {
    aa() {
      window.open('http://localhost:3000/')
      // window.open('https://192.168.123.252/')
    },
    scanCode() {
      this.$router.push({name: 'scanCode'})
    },
    onClickRight() {
      this.showScreen = true
    },

    screen_onClickLeft() {
      this.showScreen = false
    },

    screen_onClickRight() {
      this.showScreen = false
      // this.getData
      if (!this.kw) {
        this.$toast.fail('请输入查询信息！')
        return
      }
      this.$router.push({name: 'searchResult', query: {kw: this.kw}})
    },

    getData() {
      this.$http({
        url: "/project/index",
        method: "get",
      }).then((res) => {
        if (res.code === 1) {
          console.log('res', res)
          this.dataSource = res.data
          this.$nextTick(() => {
            this.$refs.www.scrollTo(0, sessionStorage.getItem("scroll"))
            // document.getElementById("scrollDiv").scrollTo(0, sessionStorage.getItem("scroll"))
          })
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    showModal(item) {
      if (!item.ms_status) {
        this.$http({
          url: "/project/ms/dep",
          method: "get",
          params: {dep: item.id}
        }).then(res => {
          if (res.code === 1) {
            console.log('res', res)
            this.$dialog.confirm({
              title: '温馨提示',
              message: '主盘部门与随盘部门签字后 才可进行盘点哦',
              confirmButtonText: '前往签字'
            })
            .then(() => {
              this.$router.push({name: 'autograph', query: {department: item.id, status: Boolean(item.status), name: item.name }})
            })
            .catch(() => {
              // on cancel
            });
            
          } else {
            this.$toast.fail(res.msg);
          }
        })

      } else {
        sessionStorage.setItem("scroll", this.$refs.www.scrollTop)
        this.$router.push({name: 'departDetail', query: {department: item.id, status: Boolean(item.status), name: item.name }})
      }
    },

    stat_assets(name, id) {
      console.log('scroll', this.$refs.www.scrollTop)
      sessionStorage.setItem("scroll", this.$refs.www.scrollTop)
      this.$router.push({name: 'stayAssets', query: {name, id}})
      // this.stayAssets_name = name
      // this.stayAssets_id = id
      // this.showHome = false
    }
  },
};
</script>

<style scoped lang="scss">
.gy_checkProject {
  // padding-top: 56px;
  overflow: scroll;
  .content {
    background-color: #f7f8f9;
    padding: 0 10px;
    .info {
      position: relative;
      background-color: #0085f5;
      padding: 15px;
      color: #fff;
      font-size: 1.5rem;
      border-radius: 8px;
      .tag {
        position: absolute;
        height: 30px;
        width: 60px;
        background-color: #fff;
        border-radius: 0 7px;
        top: 0px;
        right: 0;
        text-align: center;
        line-height: 30px;
        font-size: 14px;
      }
      .row {
        margin-bottom: 7px;
        .text {
          margin-left: 3rem;
        }
      }
    }
    .table {
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 20px 10px 10px 15px;
      margin-top: 10px;
      overflow: scroll;
      height: 700px;
      .header {
        display: flex;
        justify-content: space-between;
        .header_left {
          font-size: 1.5rem;
          font-weight: 500;
          color: #0085f5;
        }
        .header_right {
          font-size: 1.5rem;
          font-weight: 500;
          color: #c3cfda;
        }
      }
      .item {
        padding: 20px 0;
        border-bottom: 1px solid #f5f6f7;
        .title {
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          .name {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
          }
          .circle {
            width: 5px;
            height: 5px;
            background-color: #27e253;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
          }
          .icon {
            vertical-align: text-bottom;
          }
        }
        .text {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}
</style>