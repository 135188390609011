<template>
  <div class="gy_login">
    <div style="text-align: center">
      <img class="img" src="../../assets/logo.png" alt="">
    </div>
    <div>
      <van-field v-model="tel" type="tel" placeholder="请输入手机号" />
      <van-field v-model="password" type="password" placeholder="请输入密码" />
      <van-field v-model="number" type="number" placeholder="请输入项目编号" />
      <div class="tips">{{tips}}</div>
      <div class="btn_block">
        <van-button round block type="info" @click="submit" :class="{'btn_true': isTrue}">登录</van-button>
      </div>
    </div>
  </div>
  <!-- <a href="http://localhost:3000/" target="_black">aaa</a> -->
  <!-- <van-button round block type="info" @click="aa">跳转</van-button> -->
</template>
<script>
export default {
  layout: 'login',
  data() {
    return {
      tel: '',
      password: '',
      number: '',
      tips: ''
    }
  },
  computed: {
    isTrue() {
      return (this.tel && this.password && this.number)
    }
  },
  mounted() {
    let time1 = new Date().getTime()
    let time2 = localStorage.getItem("TIME")
    console.log('time2', time2)
    if (time2) {
      // alert(time2)
      if (time1 - time2 < 600000) {
        this.$router.push({name: 'checkProject'})
      }
    } else {
      if (this.$route.params.from === 'other') {
        window.location.reload()
      }
    }
  },
  methods: {
    submit() {
      // console.log('66', this.tel, this.password, this.number)
      // if (true) {
      //   this.tips = '手机号或密码错误'
      // } else if (false) {
      //   this.tips = '项目编号错误，无法找到该项目'
      // }
      let myreg=/^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!myreg.test(this.tel)) {
        this.$toast.fail('请输入正确的手机号！');
        return
      }
      // let numreg = /^[0-9]*$/
      // if (!numreg.test(this.number)) {
      //   this.$toast.fail('请输入正确的！');
      //   return
      // }
      let params = {
        account: this.tel,
        password: this.password,
        project: this.number
      }
      this.$http({
        url: "/user/login",
        method: "post",
        data: params
      }).then((res) => {
        if (res.code === 1) {
          localStorage.setItem('XS_TOKEN', res.data.userinfo.token)
          this.$router.push({name: 'checkProject'})
        } else {
          this.$toast.fail(res.msg);
        }
      });
      // this.$API('login', params).then(res => {
      //   console.log(res)
      // })
    },
    aa() {
      window.open('http://localhost:3000/')
      // window.open('https://192.168.10.201')
    }
  }
}
</script>

<style scoped lang="scss">
#app {
  background-color: #fff;
}
.gy_login {
  padding: 0 4rem;
  .img {
    width: 15rem;
    margin-bottom: 5rem;
    margin-top: 5rem;
  }
  .van-cell {
    background-color: #F7F8F9;
    height: 4rem;
    border-radius: 1rem;
    line-height: 4rem;
    padding: 0 2rem;
    margin-bottom: 1rem;
  }
  .tips {
    margin-bottom: 3.5rem;
    color: #EB1801;
    font-size: 1.4rem;
    text-align: left;
  }
  .btn_block {
    height: 68px;
    background: #ffffff;
    // position: fixed;
    // bottom: 0;
    // width: 100%;
    .van-button {
      // width: 80%;
      margin: 12px auto 0;
      background: #E2EBF2;
      border-color: #E2EBF2;
      color: #BDC8D1;
    }
    .btn_true {
      color: #fff;
      background-color: #1989fa;
      border: 1px solid #1989fa;
    }
  }
}
</style>