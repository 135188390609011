<template>
  <div class="gy_autograph">
    <div v-if="showGraph">
      <van-nav-bar
        title="部门验收"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        fixed
      >
      </van-nav-bar>
      <div class="content">
        <div class="sign_block">
          <div class="form_block">
            <van-field
              v-model="name"
              name="验收人姓名"
              label="验收人姓名"
              placeholder="输入验收人姓名"
              input-align="right"
              :rules="[{ required: true, message: '请输入验收人姓名' }]"
            />
            <van-field
              v-model="mobile"
              name="手机号"
              label="手机号"
              placeholder="输入手机号"
              input-align="right"
              :rules="[{ required: true, message: '请输入手机号' }]"
            />
            <div class="code">
              <van-field
                v-model="captcha"
                name="验证码"
                placeholder="输入验证码"
              />
              <span class="code_btn" @click="getCode()">{{showTime ? (time+'s') : '获取验证码'}}</span>
            </div>
          </div>
          <div class="graph_img" v-if="isGraph1">
            <img class="" :src="imgBase1" alt="" />
          </div>
          <div v-else class="not_graph">
            <div @click="showGraph = false">
              <img class="add_img" src="../../assets/add.png" alt="" />
              <div class="text">添加签名</div>
            </div>
          </div>
          <div class="clearGraph" @click="isGraph1 = false; imgBase1 = ''">清除</div>
        </div>
      </div>
      <div class="btn_block">
        <van-button round block type="info" @click="submit">确定</van-button>
      </div>
    </div>
    <div v-else>
      <Graph @setImgBase="setImgBase" @goBack="goBack" />
    </div>
  </div>
</template>
<script>
import Graph from "../../components/graph.vue";
export default {
  data() {
    return {
      departmentId: '',
      showGraph: true,
      imgBase1: "",
      isGraph1: false,
      name: '',
      mobile: '',
      captcha: '',
      showTime: false,
      time: 60,
    };
  },
  components: {
    Graph,
  },
  mounted() {
    this.departmentId = this.$route.query.departmentId
  },
  methods: {
    onClickLeft() {
      this.$router.push({name: 'departDetail', query: {department: this.departmentId, name: this.$route.query.name}})
    },

    getCode() {
      let params = {
        mobile: this.mobile,
        event: 'acceptance'
      }
      this.$http({
        url: "/sms/send",
        method: "post",
        data: params
      }).then(res => {
        if (res.code === 1) {
          this.$toast.success('发送验证码成功！');
          this.showTime = true
          let codeTime = setInterval(() => {
            if (this.time === 0) {
              clearInterval(codeTime)
              this.showTime = false
              this.time = 60
            }
            this.time--
          }, 1000)
          console.log('res', res)
        } else {
          this.$toast.fail(res.msg);
        }
      })
    },

    setImgBase(data) {
      console.log('data', data)
      this.showGraph = true;
      this.isGraph1 = true;
      this.imgBase1 = data;
    },

    dataURLtoFile(dataurl,filename = '') {
      var arr = dataurl.split(','),
        // mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      // if (mime) {
      //   var mime_arr = mime.split('/')
      //   if (filename == '') {
      //     filename = randomStr(6) + '.' + mime_arr[1]
      //   }
      // }
      return new File([u8arr], filename)
    },

    goBack() {
      this.showGraph = true;
    },

    submit() {
      if (!(this.imgBase1)) {
        this.$toast.fail('请添加签名！')
        return
      }
      let formData1 = new FormData()
      formData1.append("file", this.dataURLtoFile(this.imgBase1, 'a.png'))
      formData1.append("category", 'accept')

      this.$http({
        url: "/common/upload",
        method: "post",
        data: formData1
      }).then(res => {
        if (res.code === 1) {
          let params = {
            mobile: this.mobile,
            event: 'acceptance',
            name: this.name,
            captcha: this.captcha,
            dep: this.departmentId,
            sign: res.data.url,
          }
          this.$http({
            url: "/sms/check",
            method: "post",
            data: params
          }).then(res => {
            if (res.code === 1) {
              console.log('res', res)
              this.$toast.success('验收成功！');
              this.$router.push({name: 'departDetail', query: {department: this.departmentId, name: this.$route.query.name, status: true}})
            } else {
              this.$toast.fail(res.msg);
            }
          })
        }
      })
    }
  },
};
</script>

<style scoped lang="scss">
.gy_autograph {
  padding-top: 56px;
  .content {
    padding: 0 10px 10px;
    .sign_block {
      height: 500px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgb(219, 219, 235);
      border-radius: 10px;
      padding: 15px;
      margin-top: 10px;
      .form_block {
        .code {
          display: flex;
          align-items: center;
          background: #fff;
          .code_btn {
            width: 150px;
            height: 40px;
            background: #DEF0FF;
            border-radius: 20px;
            line-height: 40px;
            text-align: center;
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #0085F5;
            margin-right: 13px;
          }
        }
      }
      .graph_img {
        height: 60%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .not_graph {
        height: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        .add_img {
          width: 50px;
        }
        .text {
          font-size: 1.5rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #bcbcbc;
          margin-top: 10px;
        }
      }
      .clearGraph {
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0085f5;
        float: right;
      }
    }
  }
  .btn_block {
    height: 68px;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    width: 100%;
    .van-button {
      width: 80%;
      margin: 12px auto 0;
    }
  }
}
</style>