<template>
  <div>
    <!-- <button class="btn" v-if="!scaning" @click="scanClickHandler">
      扫一扫
    </button> -->
    <!-- <button class="btn" @click="stopScan">停止扫描</button> -->
    <div class="qrcode-bg">
      <div id="qr-code-reader"></div>
    </div>
    <van-button block type="info" @click="stopScan" style="width: 120px;border-radius: 10px;margin: auto;margin-top: 50px;">停止扫描</van-button>
  </div>
</template>

<script>
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";
const supports = [
  Html5QrcodeSupportedFormats.CODE_128,
  Html5QrcodeSupportedFormats.CODE_39,
  Html5QrcodeSupportedFormats.CODE_93,
  Html5QrcodeSupportedFormats.ITF,
  Html5QrcodeSupportedFormats.EAN_13,
  Html5QrcodeSupportedFormats.EAN_8,
  Html5QrcodeSupportedFormats.ITF,
  Html5QrcodeSupportedFormats.UPC_A,
  Html5QrcodeSupportedFormats.UPC_E,
  Html5QrcodeSupportedFormats.RSS_14,
];
export default {
  data() {
    return {
      html5QrCode: null,
      scaning: false,
      config: {
        fps: 10,
        qrbox: { width: 280, height: 150 },
        formatsToSupport: supports,
      },
    };
  },
  mounted() {
    this.html5QrCode = new Html5Qrcode("qr-code-reader");
    this.scanClickHandler()
  },
  methods: {
    scanClickHandler() {
      this.scaning = true;
      Html5Qrcode.getCameras()
        .then((devices) => {
          console.log("devices", devices);
          if (devices && devices.length) {
            let cameraId = "";
            const camera = devices.find(
              (cs) => cs.facingMode === "environment"
            );
            if (camera) {
              cameraId = camera.id;
              this.startWithCameraId(cameraId);
              // cameraId = devices[0].id;
            } else {
              this.startWithoutCameraId();
            }

          } else {
            this.startWithoutCameraId();
          }
        })
        .catch((err) => {
          this.scaning = false;
          console.log("没有获取摄像头设备...", err);
          this.$emit("onError", "没有获取摄像头设备..., " + err);
        });
    },
    onScanSuccess(decodedText, decodedResult) {
      // console.log('decodedText',decodedText,decodedResult,supports)
      const format = decodedResult.result.format.format;

      if (supports.includes(format)) {
        this.scaning = false;
        console.log("扫码成功结果:\n" + decodedText, decodedResult);
        this.$emit("onSuccess", decodedText, decodedResult);
        // this.stopScan();
      }
    },
    onScanFailure() {
      // console.log("扫码失败:\n" + error);
    },
    startWithCameraId(cameraId) {
      this.html5QrCode
        .start(
          { deviceId: { exact: cameraId } },
          this.config,
          this.onScanSuccess,
          this.onScanFailure
        )
        .catch((err) => {
          console.log("通过摄像头扫码异常....", err);
          this.scaning = false;
          this.$emit("onError", "通过摄像头扫码异常..., " + err);
        });
    },
    startWithoutCameraId() {
      this.html5QrCode
        .start(
          {
            facingMode: { exact: "environment" },
          },
          this.config,
          this.onScanSuccess,
          this.onScanFailure
        )
        .catch((err) => {
          console.log("通过摄像头startWithoutCameraId扫码异常....", err);
          this.scaning = false;
          this.$emit("onError", "通过摄像头扫码异常..., " + err);
        });
    },
    stopScan() {
      // console.log('this.html5QrCode',this.html5QrCode)
      if (!this.html5QrCode || !this.html5QrCode.isScanning) return;
      this.html5QrCode
        .stop()
        .then(() => {
          // QR Code scanning is stopped.
          console.log("QR Code scanning stopped.");
          this.$router.push({name: 'checkProject'})
          // this.scaning = false;
        })
        .catch(() => {
          // Stop failed, handle it.
          console.log("Unable to stop scanning.");
          this.$router.push({name: 'checkProject'})
          // this.scaning = false;
        });
    },
  },
  beforeDestroy() {
    // console.log("beforeDestroy");
    this.stopScan();
  },
};
</script>
<style scoped>
.btn {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 5px 20px;
}
#qr-code-reader {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.qrcode-bg {
  position: relative;
  height: 500px;
  width: 500px;
  max-width: 100%;
  max-height: 50vh;
  background: rgba(0, 0, 0, 0);
  margin: auto;
}
</style>
