<template>
  <div class="scanCode">
    <div class="hello" v-if="showScan">
      <!-- <Html5Qrcode @onSuccess="onSuccess" @onError="onError" /> -->
      <QuaggaScanner @onSuccess="onSuccess" @onError="onError" />
    </div>
    <div v-else>
      <van-nav-bar title="扫码结果" left-arrow @click-left="onClickLeft" fixed>
      </van-nav-bar>

      <div class="content">
        <div style="marginbottom: 12px; font-size: 15px; margin-left: 6px">
          资产编号：{{ result }}, 有{{ dataList.length }}条盘点信息
        </div>
        <div class="info">
          <van-field
            center
            clearable
            v-model="keyWord"
            placeholder="请输入盘点编号"
            size="small"
          >
            <van-button slot="button" type="info" round @click="searchResult">
              搜索
            </van-button>
          </van-field>
        </div>
        <div class="table" v-if="dataList.length > 0">
          <div v-for="(item, index) in dataList" :key="index" class="item">
            <div @click="turnAddAssets(item)">
              <div class="title">
                <div class="name">
                  {{ item.name }}
                  <!-- <span style="font-size: 15px; margin-left: 6px">
                    <span style="color: #aea3a3">{{ item.linked }}</span>
                    /{{ item.quantity }}
                  </span> -->
                  <span v-if="item.reply_status" class="dai">转</span>
                </div>
                <div>
                  <span
                    class="circle"
                    :style="{
                      background:
                        item.status < 4 && item.status > 0
                          ? '#27E253'
                          : '#efefef',
                    }"
                    >{{ item.status | filterStatus }}</span
                  >
                  <van-icon name="arrow" class="icon" />
                </div>
              </div>
              <div class="detail">
                <span>盘点编号：{{ item.check_no }}</span>
                <span style="marginLeft: 30px" v-if="item.type_main"
                  >{{ item.type_main }}-{{ item.type_sub }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          style="text-align: center; font-size: 15px; font-weight: bold"
        >
          <div style="margin-top: 30px; margin-bottom: 10px">
            该资产编号关联的盘点编号中
          </div>
          <div>不存在您搜索的盘点编号</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import QrcodeScanner from "./QrcodeScanner.vue";
// import Html5Qrcode from "./Html5Qrcode.vue";
import QuaggaScanner from "./QuaggaScanner.vue";
export default {
  name: "HelloWorld",
  components: {
    // QrcodeScanner,
    // Html5Qrcode,
    QuaggaScanner
  },
  data() {
    return {
      // result: "TY2016000136",
      result: '',
      decodedResult: "",
      showScan: true,
      keyWord: '',
      dataList: []
    };
  },
  mounted() {
    // this.getData();
  },
  filters: {
    filterStatus(value) {
      switch (value) {
        case 1:
          return "A+";
        case 2:
          return "A";
        case 3:
          return "B";
      }
    },
  },
  methods: {
    onClickLeft() {
      // this.$router.push({ name: "checkProject" });
      this.showScan = true
    },
    onSuccess(result, decodedResult) {
      console.log("onSuccess", result, decodedResult);
      this.result = result;
      this.decodedResult = JSON.stringify(decodedResult);
      this.getData();
    },
    getData() {
      this.$http({
        url: "/inventory/scan/check_no",
        method: "get",
        params: { check_no: this.result },
      }).then((res) => {
        if (res.code === 1) {
          if (res.data.list.length > 1) {
            // this.$router.push();
            this.showScan = false;
            this.dataList = res.data.list;
          } else if (res.data.list.length === 1) {
            this.$router.push({
              path: "/addAssets/scanCode-index",
              query: { id: res.data.list[0].id, departmentId: res.data.list[0].department },
            });
          } else {
            this.$toast.fail(`资产编号${this.result}信息不存在，请重新扫描！`);
          }
        } else {
          this.$toast.fail(`资产编号${this.result}信息不存在，请重新扫描！`);
        }
      });
    },
    searchResult() {
      if (this.keyWord) {
        console.log('66', this.dataList, this.keyWord)
        let list = this.dataList.filter(e => e.check_no == this.keyWord)
        console.log('8', list)
        this.dataList = list
      } else {
        this.getData()
      }
    },
    turnAddAssets(item) {
      this.$router.push({
        path: "/addAssets/scanCode-index",
        query: { id: item.id, departmentId: item.department },
      });
    },
    onError(error) {
      console.log("onError", error);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.scanCode {
  padding-top: 56px;
  .content {
    background-color: #f7f8f9;
    padding: 10px 10px;
    .info {
      margin-top: 20px;
      .van-button {
        height: 30px;
      }
      .van-cell {
        padding: 8px 16px;
        border-radius: 10px;
      }
    }
    .table {
      background: #ffffff;
      // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 0 10px 15px 15px;
      margin-top: 20px;
      font-size: 15px;
      .header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #efecec;
        padding-bottom: 15px;
        .header_left {
          font-size: 1.5rem;
          font-weight: 500;
          color: #0085f5;
        }
        .header_right {
          font-size: 1.5rem;
          font-weight: 500;
          color: #f5a317;
        }
      }
      .item {
        padding: 20px 0;
        border-bottom: 1px solid #f5f6f7;
        .title {
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .name {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            .text {
              font-size: 1.4rem;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #27e253;
              margin-left: 10px;
            }
            .dai {
              color: #ff8100;
              font-size: 13px;
              background-color: #e7dfd1;
              border-radius: 5px;
              display: inline-block;
              text-align: center;
              padding: 1px;
            }
            .lu {
              color: #409eff;
              font-size: 13px;
              background-color: #e4f2ff;
              border-radius: 5px;
              display: inline-block;
              text-align: center;
              padding: 1px;
            }
          }
          .circle {
            width: 21px;
            height: 21px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
            color: #fff;
            line-height: 21px;
            text-align: center;
          }
        }
        .detail {
          font-size: 1.5rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          padding: 10px 0;
          line-height: 28px;
          padding-bottom: 0;
          .custom-remark {
            background: #f9f9f9;
            padding: 15px;
            border-radius: 12px;
          }
        }
        .text {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}
</style>
