<template>
  <div class="gy_assetsGL">
    <van-nav-bar
      title="盘点关联"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
      fixed
    >
      <template #right>
        <van-icon name="search" />
      </template>
    </van-nav-bar>

    <div class="mask" v-if="showScreen" @touchmove.prevent>
      <van-nav-bar
        title="搜关联资产"
        left-arrow
        @click-left="screen_onClickLeft"
        @click-right="screen_onClickRight"
      >
        <template #right>
          <span class="search">搜索</span>
        </template>
      </van-nav-bar>

      <!-- <van-cell class="assetSearch" is-link title="资产类别" @click="showType = true" :value="type_name" /> -->
      <div @click="showDep = true" class="assetSearch">
        <div class="byc">
          <span class="left">使用部门</span>
          <span class="right">
            {{ department_name }}
            <van-icon name="arrow" color="#969799" />
          </span>
        </div>
      </div>
      <van-popup v-model="showDep" round position="bottom">
        <van-picker
          show-toolbar
          :columns="depList"
          @cancel="showDep = false"
          @confirm="onConfirm_dep"
          @change="department_change"
        />
      </van-popup>

      <van-field
        v-model="kw"
        placeholder="请输入资产编号、名称、品牌、规格型号"
      />
      <div style="height: 100%" @click="showScreen = false"></div>
    </div>

    <div class="content">
      <div v-if="glBtn_name !== '查看'" class="fiter">
        <div :class="[{'active': active === 0} , 'fiter-item']" @click="filterData(0)">全部</div>
        <div :class="[{'active': active === 1} , 'fiter-item']" @click="filterData(1)">未关联</div>
        <div :class="[{'active': active === 2} , 'fiter-item']" @click="filterData(2)">已关联</div>
      </div>
      <div>发现{{ total }}条可能相关资产</div>
      <div v-if="glBtn_name === '查看'">
        <div v-for="(item, index) in dataList" :key="index" class="item">
          <div class="title">
            <div>
              <span class="text1">{{ item.item_name }}</span>
              <span class="text2">{{ item.linked | linkFilter }}</span>
            </div>
            <div v-if="!not_gl">
              <van-button
                v-if="item.linked !== 1"
                round
                block
                type="info"
                :class="['btn', !item.is_link ? 'gl' : 'not_gl']"
                @click="gl_click(item)"
                >{{ !item.is_link ? "关联" : "解除关联" }}</van-button
              >
            </div>
          </div>
          <div class="detail">
            <div>资产编号：{{ item.item_no }}</div>
            <div>类别：{{ item.main_type }}-{{ item.sub_type }}</div>
            <div>品牌：{{ item.brand }}</div>
            <div>规格型号：{{ item.model }}</div>
            <div>
              使用部门：{{
                item.use_dep
                  ? typeof item.use_dep === "string"
                    ? item.use_dep
                    : item.use_dep[0]
                  : ""
              }}
            </div>
            <div>
              管理部门：{{
                item.manage_dep
                  ? typeof item.manage_dep === "string"
                    ? item.manage_dep
                    : item.manage_dep[0]
                  : ""
              }}
            </div>
            <div>使用人：{{ item.user }}</div>
            <div>存放地点：{{ item.stock_addr }}</div>
          </div>
        </div>
      </div>
      <div v-else>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="(item, index) in dataList" :key="index" class="item">
            <div class="title">
              <div>
                <span class="text1">{{ item.item_name }}</span>
                <span style="font-size: 15px;margin-left: 6px;">
                  <span style="color: #aea3a3;">{{item.checked}}</span>
                  /{{item.quantity}}
                </span>
                <span class="text2">{{ item.linked | linkFilter }}</span>
              </div>
              <div v-if="!not_gl">
                <van-button
                  v-if="item.linked !== 1"
                  round
                  block
                  type="info"
                  :class="['btn', !item.is_link ? 'gl' : 'not_gl']"
                  @click="gl_click(item)"
                  >{{ !item.is_link ? "关联" : "解除关联" }}</van-button
                >
                <!-- item.is_link, item.item_no, item.id -->
              </div>
            </div>
            <div class="detail">
              <div>资产编号：{{ item.item_no }}</div>
              <div>类别：{{ item.main_type }}-{{ item.sub_type }}</div>
              <div>品牌：{{ item.brand }}</div>
              <div>规格型号：{{ item.model }}</div>
              <div>
                使用部门：{{
                  item.use_dep
                    ? typeof item.use_dep === "string"
                      ? item.use_dep
                      : item.use_dep[0]
                    : ""
                }}
              </div>
              <div>
                管理部门：{{
                  item.manage_dep
                    ? typeof item.manage_dep === "string"
                      ? item.manage_dep
                      : item.manage_dep[0]
                    : ""
                }}
              </div>
              <div class="row">
                <span>单价金额</span>
                <span class="text"> {{
                  item.price
                }} </span>
              </div>
              <div>使用人：{{ item.user }}</div>
              <div>存放地点：{{ item.stock_addr }}</div>
              <van-field
                v-model="item.remark"
                class="remarks"
                name=""
                label=""
                placeholder="该产品备注信息"
                :disabled="true"
              />
            </div>
          </div>
        </van-list>
      </div>
    </div>

    <van-popup v-model="showGL" round position="bottom">
      <van-picker
        show-toolbar
        :columns="dGlList"
        @cancel="showGL = false"
        @confirm="onConfirm_GL"
      />
    </van-popup>

    <!-- 替换关联盘点资产列表 -->
    <van-popup v-model="showTH" round position="bottom" class="TH_class">
      <van-nav-bar
        title="替换关联"
        left-arrow
        @click-left="TH_onClickLeft"
        @click-right="TH_onClickRight"
        fixed     
      >
        <template #left> 取消 </template>
        <template #right> 确定替换 </template>
      </van-nav-bar>
      <div class="content">
        <van-radio-group v-model="radio">
          <div v-for="(item, index) in dataList2" :key="index" class="item">
            <div class="title">
              <div>
                <span class="text1">{{ item.name }}</span>
                <span class="text2">{{ item.status | linkFilter }}</span>
              </div>
              <van-radio :name="index" />
            </div>
            <div class="detail">
              <div>盘点编号：{{ item.check_no }}</div>
              <div>资产名称：{{ item.name }}</div>
              <div>资产类别：{{ item.type_main }}-{{ item.type_sub }}</div>
              <div>资产品牌：{{ item.brand }}</div>
              <div>规格型号：{{ item.model }}</div>
              <div>
                实盘使用部门：{{
                  item.department
                    ? typeof item.department === "string"
                      ? item.department
                      : item.department[0]
                    : ""
                }}
              </div>
              <!-- <div>
                管理部门：{{
                  item.manage_dep
                    ? typeof item.manage_dep === "string"
                      ? item.manage_dep
                      : item.manage_dep[0]
                    : ""
                }}
              </div> -->
            </div>
          </div>
        </van-radio-group>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      num: 66,
      dataList: [],
      showScreen: false,
      kw: "",
      showGL: false,
      dGlList: ["A级关联", "B级关联"],
      showDep: false,
      // typeList: [],
      depList: [],
      department: '',
      department_name: '',
      // type_name: "",
      // type_main: null,
      // type_sub: null,
      item_no: "",
      id: "",
      not_gl: false,
      // 替换
      showTH: false,
      radio: 1,
      GL_info: {},
      dataList2: [],
      linked_id: '',
      loading: false,
      total: 0,
      finished: false,
      page: 1,
      active: 1
    };
  },

  filters: {
    linkFilter(value) {
      switch (value) {
        case 0:
          return "";
        case 1:
          return "A+级关联";
        case 2:
          return "A级关联";
        case 3:
          return "B级关联";
      }
    },
  },

  props: ["brand", "name", "check_no", "glBtn_name", "AAsearch_id", "dep"],

  mounted() {
    if (this.glBtn_name === "查看") {
      this.getShowData()
    } else {
      // this.onLoad()
    }
    this.getDeplist();
    // console.log("99", this.$route.query.id);
  },

  methods: {
    getShowData() {
      this.$http({
        url: "/inventory/item/id",
        method: "get",
        params: { id: this.AAsearch_id },
      }).then((res) => {
        if (res.code === 1) {
          this.dataList = [res.data];
          this.total = this.dataList.length
          this.not_gl = true;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    
    getData() {
      let params = {
        name: this.name,
        dep: this.dep ? this.dep : Number(this.$route.query.departmentId),
        check_no: this.check_no,
        page: this.page,
        type: this.active,
      };
      this.loading = true;
      this.$http({
        url: "/inventory/matchsearch",
        method: "post",
        data: params,
      }).then((res) => {
        if (res.code === 1) {
          this.dataList = this.dataList.concat(res.data.list)
          this.total = res.data.total
          this.loading = false;
          if (this.page < Math.ceil(this.total/50)) {
            console.log('66', this.total, this.page)
            this.page++
          } else {
            console.log('88', this.total, this.page)
            this.finished = true
          }
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    getFilterData() {
      let params = {
        name: this.name,
        dep: this.dep ? this.dep : Number(this.$route.query.departmentId),
        check_no: this.check_no,
        page: 1,
        type: this.active
      };
      console.log('9999')
      this.$http({
        url: "/inventory/matchsearch",
        method: "post",
        data: params,
      }).then((res) => {
        if (res.code === 1) {
          this.dataList = res.data.list
          this.total = res.data.total
          this.loading = false;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    onLoad() {
      console.log('111')
      this.getData()
    },

    filterData(active) {
      this.active = active
       
      this.loading=true
      this.finished = false
        this.page = 1
        this.dataList = []
        this.total = 0
        this.getData()     
    },

    onClickLeft() {
      this.$emit("showAddPage2");
    },

    onClickRight() {
      this.showScreen = true;
    },

    screen_onClickLeft() {
      this.showScreen = false;
    },

    // 头部筛选搜索
    screen_onClickRight() {
      console.log("kw", this.kw, this.type_main, this.type_sub);
      this.showScreen = false;
      let params = {
        kw: this.kw,
        dep: this.department,
        check_no: this.check_no,
      };
      this.$http({
        url: "/inventory/search",
        method: "post",
        data: params,
      }).then((res) => {
        if (res.code === 1) {
          // this.dataList = res.data;
          this.dataList = res.data.list;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    gl_click(item) {
      console.log(item);
      // 替换逻辑
      if (!item.is_link) {
        if (item.link_list.length > 0) {
          this.showTH = true
          this.dataList2 = item.link_list
          this.linked_id = item.id
          this.item_no = item.item_no
        } else {
          this.showGL = true
          this.GL_info = item
        }
      } else {
        this.$dialog.confirm({
          title: "温馨提示",
          message: "是否解除该资产关联关系",
          confirmButtonText: "确定解除",
        })
        .then(() => {
          this.$http({
            url: "/inventory/remove",
            method: "post",
            data: {id: item.id}
          }).then((res) => {
            if (res.code === 1) {
              this.$toast.success('解绑成功！');
              this.$emit("showAddPage_remove");
            } else {
              this.$toast.fail(res.msg);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
      }
    },

    TH_onClickLeft() {
      this.showTH = false;
    },
    TH_onClickRight() {
      this.showGL = false;
      this.$emit("showAddPage", {
        ...this.dataList2[this.radio], 
        item_no: this.item_no,
        replace: this.dataList2[this.radio].id,
        id: this.linked_id,
        linked_name: this.dataList2[this.radio].status === 1 ? 'A+级关联' : this.dataList2[this.radio].status === 2 ? 'A级关联' : this.dataList2[this.radio].status === 3 ? 'B级关联' : ''
      });
    },

    onConfirm_GL(value) {
      console.log(value);
      this.showGL = false;
      this.$emit("showAddPage", {...this.GL_info, linked_name: value});
    },

    getDeplist() {
      this.$http({
        url: "/project/deplist",
        method: "get",
      }).then((res) => {
        if (res.code === 1) {
          let list = res.data
          list.unshift({id: '', name: "全部"})
          this.depObj = list
          this.depList = this.depObj.map(e => e.name)
          if (!this.$route.query.id) {
            this.department = this.$route.query.departmentId
            console.log('this.department', this.department, this.depObj.filter(e => e.id == this.department))
            this.department_name = this.depObj.filter(e => e.id == this.department)[0].name
          }
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    onConfirm_dep(value) {
      this.department_name = this.depObj.find(e => e.name === value).name
      this.department = this.depObj.find(e => e.name === value).id
      this.showDep = false
    },

    // getFL() {
    //   this.$http({
    //     url: "/project/type",
    //     method: "post",
    //   }).then((res) => {
    //     if (res.code === 1) {
    //       console.log("res", res);
    //       let list = res.data;
    //       this.typeList = this.recursionData(list);
    //       console.log("888", this.typeList);
    //     } else {
    //       this.$toast.fail(res.msg);
    //     }
    //   });
    // },
    // recursionData(list) {
    //   let arr = list.map((e) => {
    //     e.text = e.name;
    //     e.value = e.id;
    //     if (e.children && e.children.length > 0) {
    //       this.recursionData(e.children);
    //     } else if (e.children && e.children.length == 0) {
    //       e.children = [{ text: "", id: "" }];
    //     }
    //     return e;
    //   });
    //   console.log("999", arr);
    //   return arr;
    // },
    // onConfirm_type() {
    //   let ref = this.$refs["pickerType"].getValues();
    //   console.log(ref);
    //   this.type_main = ref[0].id;
    //   this.type_sub = ref[1].id;
    //   this.type_name = ref[0].name + (ref[1].name ? "-" + ref[1].name : "");
    //   this.showType = false;
    // },
  },
};
</script>

<style scoped lang="scss">
.gy_assetsGL {
  padding-top: 56px;
  .content {
    padding: 15px;
    .fiter {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .fiter-item {
        width: 30%;
        border: 1px solid #f3f5f7;
        background-color: #f3f5f7;
        height: 40px;
        border-radius: 5px;
        line-height: 40px;
        font-size: 16px;
        text-align: center;
      }
      .active {
        border-color: #DEF0FF;
        background-color: #DEF0FF;
        color: #0085F5;
      }
    }
    .item {
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 20px 15px;
      margin-top: 10px;
      .title {
        padding-bottom: 10px;
        border-bottom: 1px solid #f5f6f7;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text1 {
          font-size: 1.8rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
        }
        .text2 {
          font-size: 1.4rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #27e253;
          margin-left: 10px;
        }
        .btn {
          width: 90px;
          height: 40px;
        }
        .gl {
        }
        .not_gl {
          color: #0085f5;
          background-color: #def0ff;
          border-color: #def0ff;
        }
      }
      .detail {
        font-size: 1.5rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        padding: 10px 0;
        line-height: 28px;
        padding-bottom: 0;
      }
    }
  }

  .TH_class {
    top: 50px;
    .van-nav-bar--fixed {
      position: unset;
    }
  }
}
</style>