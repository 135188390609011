import vue from 'vue';

export const store =  vue.observable({departList: {
  list:[],
  total:0,
  page:1,
  status:null
}});

export const mutation = {
  setDepartList(data){
    store.departList = data
  },
}