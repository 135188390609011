<template>
  <div v-if="showHistory" class="systemName">
    <van-popup v-model="showHistory" round position="bottom" class="TH_class" :close-on-click-overlay="false">
      <van-nav-bar
        title="历史记录"
        @click-right="name_onClickRight"
        fixed
      >
        <template #right> 取消 </template>
        <!-- <template #right> 确定 </template> -->
      </van-nav-bar>
      <div class="content">
        <div style="    margin-bottom: 15px; font-size: 13px;">
          盘点编号： {{bh}}
          <span style="margin-left: 30px">资产名称：{{mc}}</span>
        </div>
        <div class="custom-remark" v-for="(item, index) in remark_list" :key="index">
          <div class="row1">
            <span>客户备注:</span>
          </div>
          <div class="row2" style="margin: 10px 0;">{{item.content}}</div>
          <div class="row3" style="color: #c1bcbc;">
            <span>{{item.createtime}}</span>
            <span style="margin-left: 40px">{{item.user}}</span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      system_name: '',
      datalist: [],
      active: ''
    }
  },
  props: [
    'showHistory',
    'remark_list',
    'bh',
    'mc'
  ],
  mounted() {
    
  },
  methods: {
    
    name_onClickRight() {
      this.$emit('close')
    },

    
  }
}
</script>

<style scoped lang="scss">
  .systemName /deep/ {
    top: 50px;
    .van-nav-bar--fixed {
      position: unset;
    }
    .content {
      padding: 20px;
      max-height: 80vh;
    overflow: auto;
      .custom-remark {
        background: #f9f9f9;
        padding: 15px;
        border-radius: 12px;
        margin-bottom: 10px;
      }
    }
  }
</style>