export default function addWaterMask(img,rotate=15,content,color="rgba(184, 184, 184, 0.6)") {

  const canvas = document.createElement("canvas");
  const width = img.width;
  const height = img.height;
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, width, height)
  ctx.textAlign = "left";
  ctx.textBaseline = 'middle';
  const fontSize = width * 0.02
  const gapx = width * 0.05
  const gapy = width * 0.05
  console.log('fontSize',fontSize)
  ctx.font = fontSize + 'px Arial';

  ctx.fillStyle = color;

   
  
  const startY = 0 - height
  const textMetrics = ctx.measureText(content)
  const textwidth =  Math.ceil(textMetrics.actualBoundingBoxRight + textMetrics.actualBoundingBoxLeft)
  const textheight = Math.ceil(textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent)

  const [w,h] = calculateRightTriangleSides(textwidth, rotate)

  const rh  = (Math.max(h, textheight))

  ctx.rotate((Math.PI / 180) * rotate);

  const lines = Math.floor((height * 2) /rh ) 
  const rows  = Math.ceil(width / w)
  for(let l = 0; l< lines; l++) {
    for(let r = 0; r < rows; r++) {
      const x = (r * (w+gapx ))+ gapx 
      const y = (l * (rh +gapy))+  gapy +textheight + startY
      ctx.fillText(content, x,y);
    }
  }
  return canvas.toDataURL("image/jpeg",0.5)
}

function calculateRightTriangleSides(long,angle) {
  var radian = 2*Math.PI/360*angle;
  return [
     
      Math.ceil(Math.cos(radian) * long),
      Math.ceil(Math.sin(radian) * long),
];
  
}