<template>
  <div class="gy_departDetail">
    <van-nav-bar
      title="搜索结果"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
      fixed
    >
      <!-- <template #right>
        <van-icon name="search" />
      </template> -->
    </van-nav-bar>
    <!-- <div class="mask" v-if="showScreen">
      <van-nav-bar title="搜索盘点资产" left-arrow @click-left="screen_onClickLeft" @click-right="screen_onClickRight">
        <template #right>
          <span class="search">搜索</span>
        </template>
      </van-nav-bar>
      <van-field v-model="kw" placeholder="请输入资产编号、名称、品牌、规格型号" />
    </div> -->

    <div class="content">
      <div style="margin-bottom: 10px; font-size: 13px">搜索到{{num}}条可能相关资产</div>
      <div class="info">
        <div class="row" @click="showType = true">
          <span>资产类别</span>
          <span class="text">
            {{type_name}}
          </span>
        </div>
      </div>
      <div class="table">
        <!-- <div class="header">
          <span class="header_left">资产列表</span>
          <span class="header_right">{{ num }}件</span>
        </div> -->
        <div
          v-for="(item, index) in dataList"
          :key="index"
          class="item"
          @click="turnAddAssets(item.id, item.draw2lose, item.is_enter)"
        >
          <div class="title">
            <div class="name">
              {{ item.name }}
              <span v-if="item.replay_status == '0'" class="dai">待</span>
              <span v-if="item.is_enter == 1 && !item.replay_time" class="lu">录</span>
            </div>
            <div style="    display: flex;
    align-items: center;">
              <span class="circle" :style="{ background: item.status === '未关联' ? '#efefef' : '#27E253' }">{{
                item.status | filterStatus
              }}</span>
              <van-icon name="arrow" class="icon" />
            </div>
          </div>
          <div class="text">
            <span>盘点编号:{{ item.check_no }}</span>
            <span style="margin-left: 30px">{{item.type_main}}-{{ item.type_sub }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div>
      <div v-if="$route.query.status"  class="btn_block" style="width: 100%;">
        <van-button round block class="btn2" type="info" @click="dep_unlock()">部门解锁</van-button>
      </div>
      <div v-else  class="btn_block">
        <van-button round block class="btn1" type="info" @click="sign">验收签字</van-button>
        <van-button round block type="info" @click="addPd">添加盘点</van-button>
      </div>
    </div> -->
    <van-popup v-model="showLink" round position="bottom">
      <van-picker
        show-toolbar
        :columns="linkList"
        @cancel="showLink = false"
        @confirm="onConfirm_link"
      />
    </van-popup>
    <van-popup v-model="showType" round position="bottom" class="assets_popup">
      <van-picker
        show-toolbar
        :lazy-render='false'
        :columns="typeList"
        @cancel="showType = false"
        @confirm="onConfirm_type"
        ref="pickerType"
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      departmentId: '',
      O: {},
      linkList: [],
      typeList: [],
      status: null,
      type_main: null,
      type_sub: null,
      showLink: false,
      showType: false,
      showScreen: false,
      kw: '',
      num: 0,
      isDone: true,
      dataList: [],
      linked_name: '全部',
      type_name: '全部',
      showUnlock: false,
      mobile: '',
      code: '',
      showTime: false,
      time: 60,
      showTips: false
    };
  },
  filters: {
    filterStatus(value) {
      switch(value) {
        case 'A+级关联':
          return 'A+';
        case 'A级关联':
          return 'A';
        case 'B级关联':
          return 'B';
        case '未关联':
          return ''
      }
    }
  },
  mounted() {
    this.departmentId = this.$route.query.departmentId
    this.getFL()
    this.getData()
  },
  methods: {
    onClickLeft() {
      this.$router.push({ name: "checkProject" });
    },

    getData() {
      let params = {
        type_main: this.type_main,
        type_sub: this.type_sub,
        kw: this.$route.query.kw,
        dep_id: this.departmentId
      }
      this.$http({
        url: "/inventory/indexsearch",
        method: "post",
        data: params
      }).then((res) => {
        if (res.code === 1) {
          console.log('res', res)
          this.num = res.data.list.length
          this.dataList = res.data.list
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    getFL() {
      this.$http({
        url: "/project/type",
        method: "post",
      }).then((res) => {
        if (res.code === 1) {
          console.log('res', res)
          let list = res.data
          this.typeList = this.recursionData(list)
          console.log('888', this.typeList)
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    recursionData(list) {
      let arr = list.map(e =>  {
        e.text = e.name
        e.value = e.id
        if (e.children && e.children.length > 0) {
          this.recursionData(e.children)
        } else if (e.children && e.children.length == 0) {
          e.children = [
            {text: '', id: ''}
          ]
        }
        return e
      })
      console.log('999', arr)
      return arr
    },

    onConfirm_link(value) {
      this.status = Object.keys(this.O)[Object.values(this.O).findIndex(e => e === value)]
      this.linked_name = value
      this.showLink = false
      this.getData()
    },

    onConfirm_type() {
      let ref = this.$refs['pickerType'].getValues()
      this.type_main = ref[0].id
      this.type_sub = ref[1].id
      this.type_name = ref[0].name + (ref[1].name ? ('-' + ref[1].name) : '')
      this.showType = false
      this.getData()
    },

    turnAddAssets(id, draw2lose, is_enter) {
      // sessionStorage.setItem('kw', this.$route.query.kw)
      this.$router.push({ name: "addAssets", query: {departmentId: this.departmentId, id, status: false, fromSearch: true, kw: this.$route.query.kw, isfromPankui: draw2lose == 0 ? true : false, str: draw2lose == 0&&is_enter == 0 ? 'pk' : ''} });
    },

    onClickRight() {
      this.showScreen = true
    },

    screen_onClickLeft() {
      this.showScreen = false
    },

    screen_onClickRight() {
      this.showScreen = false
      this.getData()
    },

    sign() {
      this.$router.push({name: 'departCheck', query: {departmentId: this.departmentId}})
    },

    dep_unlock() {
      this.showUnlock = true
    },
    unlock_onClickLeft() {
      this.showUnlock = false
    },
    unlock_onClickRight() {
      let params = {
        mobile: this.mobile,
        event: 'unlock',
        captcha: this.code,
        dep: this.departmentId,
      }
      this.$http({
        url: "/sms/check",
        method: "post",
        data: params
      }).then(res => {
        if (res.code === 1) {
          console.log('res', res)
          this.$router.push({name: 'departDetail', query: {department: this.departmentId}})
        } else {
          this.$toast.fail(res.msg);
        }
      })
    },
    getCode() {
      let params = {
        mobile: this.mobile,
        event: 'unlock',
        dep: this.departmentId
      }
      this.$http({
        url: "/sms/send",
        method: "post",
        data: params
      }).then(res => {
        if (res.code === 1) {
          this.$toast.success('发送验证码成功！');
          this.showTime = true
          let codeTime = setInterval(() => {
            if (this.time === 0) {
              clearInterval(codeTime)
              this.showTime = false
              this.time = 60
            }
            this.time--
          }, 1000)
          console.log('res', res)
        } else {
          this.showTips = true
          // this.$toast.fail(res.msg);
        }
      })
    },
  },
};
</script>

<style scoped lang="scss">
.gy_departDetail {
  padding-top: 56px;
  .content {
    background-color: #f7f8f9;
    padding: 10px 10px;
    .info {
      padding: 0 15px;
      color: #fff;
      font-size: 1.5rem;
      border-radius: 8px;
      background-color: #fff;
      color: #000;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      .row {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f5f6f7;
        padding: 20px 0;
        .text {
          margin-left: 3rem;
        }
      }
    }
    .table {
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 15px 10px 15px 15px;
      margin-top: 10px;
      .header {
        display: flex;
        justify-content: space-between;
        .header_left {
          font-size: 1.5rem;
          font-weight: 500;
          color: #0085f5;
        }
        .header_right {
          font-size: 1.5rem;
          font-weight: 500;
          color: #c3cfda;
        }
      }
      .item {
        padding: 20px 0;
        border-bottom: 1px solid #f5f6f7;
        .title {
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .name {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            .dai {
              color: #ff8303;
              font-size: 13px;
              background-color: #f7eede;
              border-radius: 50%;
              display: inline-block;
              width: 19px;
              height: 19px;
              text-align: center;
              margin-left: 8px;
            }
            .lu {
              color: #409eff;
              font-size: 13px;
              background-color: #e4f2ff;
              border-radius: 5px;
              display: inline-block;
              text-align: center;
              padding: 1px;
            }
          }
          .circle {
            width: 21px;
            height: 21px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
            color: #fff;
            line-height: 21px;
            text-align: center;
          }
          .icon {
            vertical-align: text-bottom;
            font-size: 18px;
          }
        }
        .text {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
  .btn_block {
    height: 68px;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    .van-button {
      width: 42%;
      margin: 12px auto 0;
    }
    .btn1 {
      background-color: #DEF0FF;
      border-color: #DEF0FF;
      color: #0085F5;
    }
    .btn2 {
      width: 92%;
    }
  }
}
.tips {
  font-size: 14px;
color: #EB1801;
background-color: #fff;
    padding: 0px 0 30px 22px;
}
</style>