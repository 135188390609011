<template>
  <div class="gy_assetsGL">
    <van-nav-bar
      title="盘点关联"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template #right>
        <van-icon name="search" />
      </template>
    </van-nav-bar>
    <div class="content">
      <div>发现{{ num }}条可能相关资产</div>
      <div v-for="(item, index) in dataList" :key="index" class="item">
        <div class="title">
          <div>
            <span class="text1">{{ item.a }}</span>
            <span class="text2">{{ item.h }}级关联</span>
          </div>
          <van-button round block type="info" :class="['btn', item.isGL ? 'gl' : 'not_gl']" @click="gl_click(item.isGL)"
            >{{item.isGL ? '关联' : '解除关联'}}</van-button
          >
        </div>
        <div class="detail">
          <div>资产编号：{{ item.b }}</div>
          <div>类别：{{ item.c }}</div>
          <div>品牌：{{ item.d }}</div>
          <div>规格型号：{{ item.e }}</div>
          <div>使用部门：{{ item.f }}</div>
          <div>管理部门：{{ item.g }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      num: 66,
      dataList: [
        {
          a: "qq",
          b: "ww",
          c: "ee",
          d: "rr",
          e: "tt",
          f: "yy",
          g: "uu",
          h: "A",
          isGL: true,
        },
        {
          a: "qq",
          b: "ww",
          c: "ee",
          d: "rr",
          e: "tt",
          f: "yy",
          g: "uu",
          h: "A",
          isGL: false,
        },
      ],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push({ name: "addAssets" });
    },

    onSelect1() {},

    onClickRight() {
      this.$dialog
        .confirm({
          title: "温馨提示",
          message: "删除后，该条录入资产数据不再保存， 是否确定删除？",
          confirmButtonText: "确定",
        })
        .then(() => {})
        .catch(() => {
          // on cancel
        });
    },

    gl_click(val) {
      console.log(val)
    },
  },
};
</script>

<style scoped lang="scss">
.gy_assetsGL {
  .content {
    padding: 15px;
    .item {
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 20px 15px;
      margin-top: 10px;
      .title {
        padding-bottom: 10px;
        border-bottom: 1px solid #f5f6f7;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text1 {
          font-size: 1.8rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
        }
        .text2 {
          font-size: 1.4rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #27e253;
          margin-left: 10px;
        }
        .btn {
          width: 90px;
          height: 40px;
        }
        .gl {

        }
        .not_gl {
          color: #0085F5;
          background-color: #DEF0FF;
          border-color: #DEF0FF;
        }
      }
      .detail {
        font-size: 1.5rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        padding: 10px 0;
        line-height: 28px;
        padding-bottom: 0;
      }
    }
  }
}
</style>