<template>
  <div class="gy_departDetail">
    <van-nav-bar
      title="待盘点"
      left-arrow
      @click-left="onClickLeft"
      fixed
    >
    </van-nav-bar>

    <div class="content">
      <div style="marginBottom: 12px;font-size: 15px;">查询部门：{{$route.query.name}}</div>
      <!-- <div style="marginBottom: 12px;font-size: 15px;">查询部门：{{stayAssets_name}}</div> -->
      <div class="info">
        <div class="row" @click="showAssets = true">
          <span>资产筛选</span>
          <span class="text">
            {{assets_name}}
            <span style="margin-left: 15px">{{total}}件</span>
            <van-icon name="arrow" class="icon" />
          </span>
        </div>
      </div>
      <!-- <div class="table" v-if="dataList.length > 0"> -->
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          class="table"
          
        >
          <div
            v-for="(item, index) in dataList"
            :key="index"
            class="item"
          >
            <div v-if="assets_name === '盘亏'">
              <div class="title">
                <div class="name">
                  {{ item.item_name }}
                  <span style="font-size: 15px;margin-left: 6px;" v-if="item.linked || item.quantity">
                    <span style="color: #aea3a3;">{{item.linked}}</span>
                    /{{item.quantity}}
                  </span>
                </div>
              </div>
              <div class="detail">
                <div>资产编号：{{ item.item_no }}</div>
                <div>类别：{{ item.type_main }}-{{ item.type_sub }}</div>
                <div>品牌：{{ item.brand }}</div>
                <div>规格型号：{{ item.model }}</div>
                <div class="row">
                  <span>单价金额：</span>
                  <span class="text"> {{
                    item.price
                  }} </span>
                </div>
                <div>使用人：{{ item.user }}</div>
                <div>存放地点：{{ item.stock_addr }}</div>
                <div style="color: #f5a317" v-if="item.change_check">平转亏：盘点编号{{ item.change_check }}</div>
                <!-- <van-field
                  v-model="item.remark"
                  class="remarks"
                  name=""
                  label=""
                  placeholder="该产品备注信息"
                  :disabled="true"
                /> -->
                <div class="custom-remark" v-if="item.remark_list.length > 0">
                  <div class="row1">
                    <span>客户备注:</span>
                    <span 
                      v-if="item.remark_list.length > 1"
                      style="float: right;color: #1989fa;" 
                      @click.stop="history(item)"
                    >历史记录</span>
                  </div>
                  <div class="row2">{{item.remark_list[0].content}}</div>
                  <div class="row3" style="color: #c1bcbc;">
                    <span>{{item.remark_list[0].createtime}}</span>
                    <span style="margin-left: 40px">{{item.remark_list[0].user}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="assets_name === '盘平'" @click="turnAddAssets(item.id, 'pk')">
              <div class="title">
                <div class="name">
                  {{ item.item_name }}
                  <span style="font-size: 15px;margin-left: 6px;">
                    <span style="color: #aea3a3;">{{item.linked}}</span>
                    /{{item.quantity}}
                  </span>
                  <span v-if="item.status" class="text">{{item.status | filterStatus}}</span>
                  <span v-if="item.reply_status" class="dai">转</span>
                </div>
              </div>
              <div class="detail">
                <div style="color: #1989fa;">盘点编号：{{ item.check_no }}</div>
                <div>资产编号：{{ item.item_no }}</div>
                <div>类别：{{ item.type_main }}-{{ item.type_sub }}</div>
                <div>品牌：{{ item.brand }}</div>
                <div class="row">
                  <span>单价金额：</span>
                  <span class="text"> {{
                    item.price
                  }} </span>
                </div>
                <!-- <van-field
                  v-model="item.remark"
                  class="remarks"
                  name=""
                  label=""
                  placeholder="该产品备注信息"
                  :disabled="true"
                /> -->
                <div class="custom-remark" v-if="item.remark_list.length > 0">
                  <div class="row1">
                    <span>客户备注:</span>
                    <span 
                      v-if="item.remark_list.length > 1"
                      style="float: right;color: #1989fa;" 
                      @click.stop="history(item)"
                    >历史记录</span>
                  </div>
                  <div class="row2">{{item.remark_list[0].content}}</div>
                  <div class="row3" style="color: #c1bcbc;">
                    <span>{{item.remark_list[0].createtime}}</span>
                    <span style="margin-left: 40px">{{item.remark_list[0].user}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="assets_name === '盘盈'" @click="turnAddAssets(item.id, 'py')">
              <div class="title">
                <div class="name">
                  {{ item.name }}
                  <span style="font-size: 15px;margin-left: 6px;" v-if="item.linked || item.quantity">
                    <span style="color: #aea3a3;">{{item.linked}}</span>
                    /{{item.quantity}}
                  </span>
                  <span v-if="item.draw2lose === 1" class="dai">原盘平</span>
                </div>
              </div>
              <div class="detail">
                <div>盘点编号：{{ item.check_no }}</div>
                <div>类别：{{ item.type_main }}-{{ item.type_sub }}</div>
                <div>品牌：{{ item.brand }}</div>
              </div>
            </div>
            <div v-else @click="turnAddAssets(item.id, 'py')">
              <div class="title">
                <div class="name">
                  {{ item.name }}
                  <!-- <span style="font-size: 15px;margin-left: 6px;" v-if="item.linked || item.quantity">
                    <span style="color: #aea3a3;">{{item.linked}}</span>
                    /{{item.quantity}}
                  </span> -->
                  <span class="lu">录</span>
                </div>
              </div>
              <div class="detail">
                <div>盘点编号：{{ item.check_no }}</div>
                <div>类别：{{ item.type_main }}-{{ item.type_sub }}</div>
                <div>品牌：{{ item.brand }}</div>
              </div>
            </div>
          </div>
        </van-list>
      <!-- </div> -->
    </div>
    <van-popup v-model="showAssets" round position="bottom">
      <van-picker
        show-toolbar
        :columns="linkList"
        @cancel="showAssets = false"
        @confirm="onConfirm_link"
      />
    </van-popup>
    <div v-if="showHistory">
      <HistoryRecord :showHistory="showHistory" :bh="bh" :mc="mc" :remark_list="remark_list" @close="close" />
    </div>
  </div>
</template>
<script>
// import { formatQueryDate } from '../../main'
import HistoryRecord from './historyRecord.vue'
export default {
  data() {
    return {
      departmentId: '',
      linkList: ['盘亏', '盘平', '盘盈', '录入'],
      dataList: [],
      showAssets: false,
      assets_name: '盘亏',
      num: 0,
      remark_list: [],
      showHistory: false,
      bh: '',
      mc: '',
      page: 1,
      loading: false,
      total: 0,
      finished: false,
    };
  },
  components: {
    HistoryRecord
  },
  // props: [
  //   'stayAssets_name',
  //   'stayAssets_id'
  // ],
  filters: {
    filterStatus(value) {
      switch(value) {
        case 1:
          return 'A+级关联';
        case 2:
          return 'A级关联';
        case 3:
          return 'B级关联';
      }
    }
  },
  mounted() {
    // this.departmentId = this.stayAssets_id
    this.departmentId = this.$route.query.id
    // this.getData()
  },
  methods: {
    onLoad() {
      console.log(
        12312
      )
       this.getData()
    },
    onClickLeft() {
      this.$router.push({ name: "checkProject" });
      // this.$emit("setShowHome")
    },

    getData() {
      let params = {
        department: this.departmentId,
        page: this.page
      }
      this.loading = true;
      let url = this.assets_name === '盘亏' ? '/project/lose' : this.assets_name === '盘平' ? '/project/draw' : this.assets_name === '盘盈' ? '/project/win' : '/project/enter'
      this.$http({
        url,
        method: "post",
        data: params
      }).then((res) => {
        if (res.code === 1) {
          this.dataList = this.dataList.concat(res.data.list)
          this.num = res.data.quantity ? res.data.quantity : res.data.total
          this.total = res.data.total
          this.loading = false;
          if (this.page < Math.ceil(this.total/50)) {
            this.page++
          } else {
            this.finished = true
          }
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    onConfirm_link(value) {
      this.assets_name = value
      this.showAssets = false
      this.loading = true
      this.finished = false
      this.page = 1
      this.dataList = []
      this.total = 0
      this.getData()
    },
    turnAddAssets(id, str) {
      this.$router.push({ name: "addAssets", query: {
        departmentId: this.departmentId, 
        id, 
        status: false,
        name: this.$route.query.name, 
        reply: false,
        isfromPankui: true,
        str
      } });
    },
    history(item) {
      // console.log('66', item)
      this.remark_list = item.remark_list
      this.bh = item.check_no || item.item_no
      this.mc = item.name || item.item_name
      this.showHistory = true
    },
    close() {
      this.showHistory = false
    }
  },
};
</script>

<style scoped lang="scss">
.gy_departDetail {
  padding-top: 56px;
  .content {
    background-color: #f7f8f9;
    padding: 10px 10px;
    .info {
      padding: 0 15px;
      color: #fff;
      font-size: 1.5rem;
      border-radius: 8px;
      background-color: #fff;
      color: #000;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      .row {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f5f6f7;
        padding: 20px 0;
        .text {
          margin-left: 3rem;
        }
        .icon {
          color: #a7a2a2;
          margin-left: 10px;
        }
      }
    }
    .table {
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 15px 10px 15px 15px;
      margin-top: 10px;
      .header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #efecec;
        padding-bottom: 15px;
        .header_left {
          font-size: 1.5rem;
          font-weight: 500;
          color: #0085f5;
        }
        .header_right {
          font-size: 1.5rem;
          font-weight: 500;
          color: #f5a317;
        }
      }
      .item {
        padding: 20px 0;
        border-bottom: 1px solid #f5f6f7;
        .title {
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .name {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            .text {
              font-size: 1.4rem;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #27e253;
              margin-left: 10px;
            }
            .dai {
              color: #ff8100;
              font-size: 13px;
              background-color: #e7dfd1;
              border-radius: 5px;
              display: inline-block;
              text-align: center;
              padding: 1px;
            }
            .lu {
              color: #409eff;
              font-size: 13px;
              background-color: #e4f2ff;
              border-radius: 5px;
              display: inline-block;
              text-align: center;
              padding: 1px;
            }
          }
        }
        .detail {
          font-size: 1.5rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          padding: 10px 0;
          line-height: 28px;
          padding-bottom: 0;
          .custom-remark {
            background: #f9f9f9;
            padding: 15px;
            border-radius: 12px;
          }
        }
          .text {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
          }
        }
    }
  }
  .btn_block {
    height: 68px;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    .van-button {
      width: 42%;
      margin: 12px auto 0;
    }
    .btn1 {
      background-color: #DEF0FF;
      border-color: #DEF0FF;
      color: #0085F5;
    }
    .btn2 {
      width: 92%;
    }
  }
}
.tips {
  font-size: 14px;
color: #EB1801;
background-color: #fff;
    padding: 0px 0 30px 22px;
}
</style>