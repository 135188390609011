<template>
  <div>
    <!-- <h1>DEFAULT</h1> -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'default',
  mounted() {
  }
}
</script>

