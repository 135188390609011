<template>
  <div id="app" :class="[url ? 'a' : 'b']">
    <component :is="layout" >
      <!-- <keep-alive include="departDetail"> -->
        <router-view ></router-view>
      <!-- </keep-alive>                 -->
    </component>
     <!-- <component :is="layout" v-else>
      <router-view ></router-view>
    </component> -->
  </div>
</template>

<script>
import _ from "lodash";
const layout = require.context("./layouts", false, /.vue/);
const layouts = {};
layout.keys().forEach(key => {
  let name = key.split(".");
  name = _.camelCase(name[1]);
  layouts[name] = layout(key).default;
});
export default {
  name: "App",
  components: layouts,
  data() {
    return {
      layout: "default",
      url: false,
      rouerName: this.$route.name
    };
  },
  watch: {
    rouerName: {
      handler(newName) {
        // alert(newName)
        if (newName === 'login') {
          this.url = true
        }
      },
      immediate: true
    }
  },
  created() {
    const layout = this.$route.meta.layout;
    if (layout) {
      this.layout = layout;
    }
    console.log('666', this.$route)
    if (this.$route.name === 'login') {
      this.url = true
    } else {
      this.url = false
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  height: 100%;
  
}
.a {
  background-color: #fff;
}
.b {
  background-color: #F7F8F9;
}
</style>
